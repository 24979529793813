/* eslint-disable react/prop-types */
import React from 'react';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import { Container } from '@components/global';
import FooterAlt from '@sections/FooterAlt';
import Rejilla from '../components/galeria/Rejilla';
import productos from '../datos/Productos';
import Visor from '../components/galeria/Visor';

const GaleriaPage = ({ location }) => {
  const initialPid = location.state ? location.state.producto : 1;
  const [pid, setPid] = React.useState(initialPid);

  const [mostrarVisor, setMostrarVisor] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const producto = productos.find(p => p.id === pid);

  if (mostrarVisor)
    return (
      <Visor
        producto={producto}
        index={index}
        cerrarVisor={() => setMostrarVisor(false)}
      />
    );

  function configurarVisor(index) {
    setIndex(index);
    setMostrarVisor(true);
  }

  return (
    <Layout>
      <Navbar sinMenu />

      <Container>
        <Rejilla abrirVisor={configurarVisor} pid={pid} setPid={setPid} />
      </Container>

      <FooterAlt />
    </Layout>
  );
};

export default GaleriaPage;
