import React from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import { StaticQuery, graphql } from 'gatsby';

import './visor.css';

// eslint-disable-next-line react/prop-types
export default function Visor({ index, producto, cerrarVisor }) {
  const [photoIndex, setPhotoIndex] = React.useState(index);

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
            nodes {
              relativePath
              id
              childImageSharp {
                original {
                  src
                }
                fixed(width: 200) {
                  src
                }
              }
            }
          }
        }
      `}
      render={data => {
        const list = filterProds(producto, data.allFile.nodes);
        const images = list.map(node => nodeToImage(node)).map(i => i.original);
        return (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={cerrarVisor}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        );
      }}
    />
  );
}

function nodeToImage(node) {
  const image = {
    original: node.childImageSharp.original.src,
    thumbnail: node.childImageSharp.fixed.src,
  };

  return image;
}

function filterProds(producto, nodes) {
  return nodes.filter(n => n.relativePath.indexOf(producto.slug) != -1);
}
