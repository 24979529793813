import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import productos from '../../datos/Productos';
import './gallery.css';

const Select = styled.select`
  display: block;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.4em 1.4em 0.3em 0.8em;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
  border-radius: 0.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;

export default function Rejilla({ abrirVisor, pid, setPid }) {
  const producto = productos.find(p => p.id === pid);

  const onChange = (a, b) => {
    setPid(productos[a.target.selectedIndex].id);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
            nodes {
              relativePath
              id
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div style={{ margin: '0 0.5em', paddingTop: '6.5em' }}>
          <Select onChange={onChange}>
            {productos.map(p => (
              <option key={p.id} selected={p.id === pid}>
                {p.nombre}
              </option>
            ))}
          </Select>
          <div className="gallery" style={{ marginTop: '1em' }}>
            {data.allFile.nodes
              .filter(n => n.relativePath.indexOf(producto.slug) != -1)
              .map((node, i) => (
                <a
                  key={i}
                  href="#"
                  onClick={() => abrirVisor(i)}
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <Img key={node.id} fluid={node.childImageSharp.fluid} />
                </a>
              ))}
          </div>
        </div>
      )}
    />
  );
}
